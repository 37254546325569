<template>
  <v-card class="mt-2">
    <v-card-text>
      <v-select
        v-model="status"
        dense
        outlined
        :label="$t('Status')"
        :items="[
          {text: $t('All'), value: 0},
          {text: $t('Joined'), value: 1},
          {text: $t('Approved'), value: 2},
          {text: $t('WaitApproval'), value: 3},
          {text: $t('NotJoin'), value: 4},
          {text: $t('Rejected'), value: 5},
        ]"
        hide-details="auto"
      ></v-select>
    </v-card-text>
    <div
      v-infinite-scroll="loadMoreTopics"
      infinite-scroll-disabled="infinityScrollBusy"
    >
      <v-list v-if="topics.length > 0">
        <template
          v-for="(item, index) in topics"
        >
          <v-list-item

            :key="item.id_topic"
          >
            <router-link
              style="text-decoration: none; color: unset; width: 100%"
              :to="{name: 'skill-share-detail', params: {id: item.id_topic}}"
            >
              <v-list-item-content>
                <v-list-item-title
                  style="white-space: break-spaces; text-overflow: unset; overflow:visible"
                  class="mb-2 justify-space-between d-flex"
                  :class="item.id_user_creator === currentUser.id_user ? 'error--text' : 'primary--text'"
                >
                  <strong style="max-width: 65%">{{ item.name }}</strong>

                  <v-chip
                    :color="getTopicStatusColor(item.status_label)"
                    small
                    :class="`v-chip-light-bg ${getTopicStatusColor(item.status_label)}--text`"
                  >
                    {{ $t(getTopicStatus(item.status_label)) }}
                  </v-chip>
                </v-list-item-title>
                <div class="mb-1">
                  <span class="text--primary">
                    <v-icon
                      size="14"
                      class="mr-1"
                    >{{ icons.mdiAccountVoice }}</v-icon><span class="text-xs ms-1">{{ item.speaker_name }}</span>
                  </span>
                </div>
                <div class="mb-1">
                  <v-icon
                    size="14"
                    class="mr-1"
                  >
                    {{ icons.mdiCalendarBlankOutline }}
                  </v-icon>
                  <span class="text-xs ms-1">{{
                    enrichTopicTime(
                      formatTimestampToDatetime(item.start_time, 'DD/MM/YYYY HH:mm'),
                      formatTimestampToDatetime(item.end_time, 'DD/MM/YYYY HH:mm')
                    )
                  }}
                  </span>
                </div>
                <div class="mb-1">
                  <v-icon
                    size="14"
                    class="mr-1"
                  >
                    {{ icons.mdiAccountMultiple }}
                  </v-icon><span class="text-xs ms-1">{{ item.participants }} / {{ item.max_seat }}</span>
                </div>
              </v-list-item-content>
            </router-link>
          </v-list-item>
          <v-divider
            v-if="index < topics.length - 1"
            :key="`divider-${item.id_topic}`"
            :inset="item.inset"
          ></v-divider>
        </template>
      </v-list>
      <div
        v-else
        class="text-center pb-2"
      >
        {{ $t("NoData") }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { getTopicStatus, getTopicStatusColor, getStatusParams, enrichTopicTime } from '@/utils/skillShareHelper'
import { mdiCalendarBlankOutline, mdiAccountMultiple, mdiAccountVoice } from '@mdi/js'
import SkillShareService from '@/services/SkillShareService'
import infiniteScroll from 'vue-infinite-scroll'
import store from '@/store'

export default {
  setup() {
    const currentUser = store.getters['auth/currentUser']
    const status = ref(0)
    const infinityScrollBusy = ref(false)
    const stopLoadMoreTopics = ref(false)
    const page = ref(1)
    const limit = 10
    const topics = ref([])

    const getData = async () => {
      try {
        const statusParams = getStatusParams(status.value)
        const resp = await SkillShareService.getListTopic({
          page: page.value,
          limit,
          as_user: 1,
          ...statusParams,
        })
        const { data } = resp.data
        if (data.length === 0) {
          stopLoadMoreTopics.value = true
        } else {
          topics.value = [...topics.value, ...data]
          page.value += 1
          stopLoadMoreTopics.value = false
        }
      } catch {}
    }

    const loadMoreTopics = async () => {
      if (!stopLoadMoreTopics.value) {
        infinityScrollBusy.value = true
        await getData()
        infinityScrollBusy.value = false
      }
    }

    watch(status, async (preValue, value) => {
      if (preValue !== value) {
        topics.value = []
        page.value = 1
        await getData()
      }
    })

    return {
      status,
      topics,
      currentUser,
      icons: {
        mdiCalendarBlankOutline,
        mdiAccountMultiple,
        mdiAccountVoice,
      },
      getTopicStatus,
      getTopicStatusColor,
      loadMoreTopics,
      infinityScrollBusy,
      enrichTopicTime,
    }
  },
  directives: { infiniteScroll },
}
</script>
